<template>
    <div>
        <Layout>
            <div class="content">
                <div class="row">
                    <div class="col-12">
                        <div class="mt-4" style="
                            background-color: #FFF2D9;
                            height: 75px;
                            border-radius: 5px;
                            padding: 1em;
                        ">
                            <div class="row">
                                <div class="col-1">
                                    <img src="../../../../../assets/images/delivery-truck.png" alt="" style="
                                    width: 46px; margin-left: 20px;
                                    ">
                                </div>
                                <div class="col-8">No. Trucking <br> <b> {{noTruck}}</b>
                                </div>
                                <div class="col-2 text-left">
                                    <div class="row">
                                        <div class="col-2">
                                            <img src="../../../../../assets/images/money.png" alt="" style="
                                            width: 19px; margin-left: 5px; margin-top: 20px;
                                            ">
                                        </div>
                                        <div class="col-10">
                                            Delivery Expense : <br>
                                            <h4 class="text-success">{{totalDelivery}}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <b-button v-b-modal.modal-1
                                        style="background-color: #F9A707; border: none; margin-top: 5px;">Copy ?
                                    </b-button>
                                    <b-modal id="modal-1" size="xl" hide-footer title="Copy Delivery Expense">
                                        <div style="background-color: #E2F4F1; height: 40px; padding: 10px;">
                                            <img src="../../../../../assets/images/bank.png" alt="" style="
                                            width: 19px; margin-left: 8px; margin-right: 10px; margin-top: 4px; margin-bottom: 5px;
                                            ">
                                            Delivery Expense: <b> Rp. 300.000</b>
                                        </div>

                                        <b-form>
                                            <div class="row mt-2">
                                                <div class="col-3">
                                                    <label for="">Number <span class="text-danger">*</span></label>
                                                    <div class="input-group">
                                                        <div v-if="inputNumber">
                                                            <select name="" id="" class="form-control"
                                                                style="width: 210px;">
                                                                <option value="">000</option>
                                                                <option value="">111</option>
                                                            </select>
                                                        </div>
                                                        <div v-else>
                                                            <input type="text" class="form-control"
                                                                style="width: 210px;">
                                                        </div>
                                                        <div class="input-group-append">
                                                            <button class="btn btn-success"
                                                                @click.prevent="changeInputNumber"><i
                                                                    class="fa fa-pencil-alt"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <label for="">Customer <span class="text-danger">*</span></label>
                                                    <select name="customer" id="" class="form-control" disabled
                                                        style="background-color: #E0E0E0;">
                                                        <option value="">PT UIX</option>
                                                        <option value="">PT UIX</option>
                                                    </select>
                                                </div>
                                                <div class="col-3">
                                                    <label for="">Term <span class="text-danger">*</span></label>
                                                    <select name="term" id="" class="form-control">
                                                        <option value="">Lorem, ipsum.</option>
                                                        <option value="">Lorem, ipsum.</option>
                                                    </select>
                                                </div>
                                                <div class="col-3">
                                                    <label for="">Currency <span class="text-danger">*</span></label>
                                                    <select name="currency" id="" class="form-control" disabled
                                                        style="background-color: #E0E0E0;">
                                                        <option value="">IDR</option>
                                                        <option value="">IDR</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-3">
                                                    <label for="">Date <span class="text-danger">*</span></label>
                                                    <!-- <input type="date" name="date" id="" class="form-control"> -->
                                                    <date-picker type="date" lang="en"></date-picker>
                                                </div>
                                                <div class="col-3">
                                                    <label for="">PO/Contract No <span
                                                            class="text-danger">*</span></label>
                                                    <input type="text" name="" id="" class="form-control">
                                                </div>
                                                <div class="col-3">
                                                    <label for="">PPN <span class="text-danger"></span></label>
                                                    <select name="ppn" id="" class="form-control">
                                                        <option value="">10%</option>
                                                    </select>
                                                </div>
                                                <div class="col-3">
                                                    <label for="">Document Code <span
                                                            class="text-danger"></span></label>
                                                    <select name="document_code" id="" class="form-control">
                                                        <option value="">Document PIB</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-3">
                                                    <label for="">Tax Type</label>
                                                    <select name="tax_type" id="" class="form-control">
                                                        <option value="">Lorem, ipsum.</option>
                                                    </select>
                                                </div>
                                                <div class="col-3">
                                                    <div class="mt-4">
                                                        <b-form-checkbox id="exampleCheck1" name="exampleCheck1">
                                                            Inclusive Tax
                                                        </b-form-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-1">
                                                    <b>Item</b>
                                                </div>
                                                <div class="col-2">
                                                    <b-form-checkbox id="exampleCheck2" name="exampleCheck2"
                                                        @change="onManual">
                                                        Manual
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <hr>
                                                </div>
                                            </div>
                                            <div v-if="addManual">
                                                <div class="row mt-2">
                                                    <div class="col-3">
                                                        <label for="">Item<span class="text-danger">*</span></label>
                                                        <select name="" id="" class="form-control">
                                                            <option value="">Item A</option>
                                                            <option value="">Item B</option>
                                                            <option value="">Item C</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-2">
                                                        <label for="">Qty <span class="text-danger">*</span></label>
                                                        <input type="text" name="" id="" class="form-control" value="12"
                                                            disabled style="background-color: #E0E0E0;">
                                                    </div>
                                                    <div class="col-2">
                                                        <label for="">Amount <span class="text-danger">*</span></label>
                                                        <input type="text" name="" id="" class="form-control"
                                                            value="Rp.300.000" disabled
                                                            style="background-color: #E0E0E0;">
                                                    </div>
                                                    <div class="col-2">
                                                        <label for="">Item Description <span
                                                                class="text-danger"></span></label>
                                                        <input type="text" name="" id="" class="form-control"
                                                            value="lorem ipsum">
                                                    </div>
                                                    <div class="col-3">
                                                        <label for="">Truck <span class="text-danger"></span></label>
                                                        <select name="" id="" class="form-control" disabled
                                                            style="background-color: #E0E0E0;">
                                                            <option value="">B 1234 UIX</option>
                                                            <option value="">B 1234 UIX</option>
                                                            <option value="">B 1234 UIX</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <hr>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-12 text-right">
                                                    <button style="
                                                    background-color: #e0e0e0;
                                                    color: #817F7F;
                                                    width: 90px;
                                                    height: 40px;
                                                    border: none;
                                                    border-radius: 3px;
                                                    margin-right: 0.5em;
                                                    " @click.prevent="$bvModal.hide('modal-1')">Cancel</button>

                                                    <b-button v-b-modal.modal-save slot="save" variant="primary">Save
                                                    </b-button>
                                                    <b-modal id="modal-save" hide-header hide-footer>
                                                        <div class="text-center" style="margin: 2rem;">
                                                            <p class="mx-4">Are you sure want to save it ?</p>
                                                            <br>

                                                            <button style="
                                                            background-color: #C4C4C4;
                                                            color: white;
                                                            width: 100px;
                                                            height: 40px;
                                                            border: none;
                                                            border-radius: 3px;
                                                            margin-right: 5px;
                                                            ">No</button>

                                                            <button style="
                                                            background-color: #51C1AD;
                                                            color: white;
                                                            width: 100px;
                                                            height: 40px;
                                                            border: none;
                                                            border-radius: 3px;
                                                            margin-left: 5px
                                                            " @click.prevent="onSave">Yes</button>
                                                        </div>
                                                    </b-modal>
                                                </div>
                                            </div>
                                        </b-form>
                                    </b-modal>
                                    <!-- <button class="btn mt-1" style="background-color: #F9A707;">Copy ?</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body text-center">
                                <vue-element-loading :active="isLoadingTable" spinner="bar-fade-scale"
                                    color="#FF6700" />
                                <Vuetable ref="tableVue" :api-mode="false" :fields="fields" :data="items"
                                    class="table table-centered table-striped" data-path="data"
                                    @vuetable:loading="false" @vuetable:load-success="false">
                                    <div slot="no" slot-scope="props" style="padding: 0.5rem;">
                                        <span style="color: black">{{props.rowData.no}}</span>
                                    </div>
                                    <div slot="no_delivery" slot-scope="props" style="padding: 0.5rem;">
                                        <span style="color: black">{{props.rowData.no_delivery}}</span>
                                    </div>
                                    <div slot="loading" slot-scope="props" style="padding: 0.5rem;">
                                        <span style="color: black">{{props.rowData.loading}}</span>
                                    </div>
                                    <div slot="unloading" slot-scope="props" style="padding: 0.5rem;">
                                        <span style="color: black">{{props.rowData.unloading}}</span>
                                    </div>
                                    <div slot="total_item" slot-scope="props" style="padding: 0.5rem;">
                                        <span style="color: black">{{props.rowData.total_item}}</span>
                                    </div>
                                    <div slot="status" slot-scope="props" style="padding: 0.2rem;">
                                        <div v-if="props.rowData.status === 'waiting'">
                                            <button class=""
                                                style="background-color: #817F7F; width: 80px; height: 25px; border: none; border-radius: 3px; color: white;  ">Waiting</button>
                                        </div>
                                        <div v-else-if="props.rowData.status === 'success'">
                                            <button class=""
                                                style="background-color: #0EA388; width: 80px; height: 25px; border: none; border-radius: 3px; color: white; ">Success</button>
                                        </div>
                                        <div v-else-if="props.rowData.status === 'failed'">
                                            <button class=""
                                                style="background-color: #EA4C40; width: 80px; height: 25px; border: none; border-radius: 3px; color: white; ">Failed</button>
                                        </div>
                                        <div v-else-if="props.rowData.status === 'on_going'">
                                            <button class=""
                                                style="background-color: #F9A707; width: 80px; height: 25px; border: none; border-radius: 3px; color: white; ">On
                                                Going</button>
                                        </div>
                                    </div>

                                    <div slot="action" slot-scope="props" style="padding: 0.1rem;">
                                        <b-dropdown id="dropdown-1" right size="sm" variant="link">
                                            <template v-slot:button-content>
                                                <button class=""
                                                    style="background-color: #CFFDF5; border-radius: 3px; border: none;">
                                                    <i class="fa fa-caret-down ml-2"
                                                        style="padding-right: 10px; color: #51C1AD"></i>
                                                </button>
                                            </template>

                                            <b-dropdown-item @click.prevent="onAction(props)"><i class="fa fa-print"></i> Print Delivery Order
                                            </b-dropdown-item>

                                            <b-dropdown-item @click.prevent="onOpenModalStatus(props)">
                                                <i class="fa fa-edit"></i> Update Status
                                            </b-dropdown-item>
                                            <b-dropdown-item href="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                </svg>
                                                View GPS Maps
                                            </b-dropdown-item>
                                        </b-dropdown>

                                    </div>
                                </Vuetable>


                                <!-- Modal -->



                                <!-- modal update -->
                                <b-modal id="modal-delete-update-status" title="Update Status" hide-footer>
                                    <div class="" style="margin: 1rem;">
                                        <label for="">Status <span class="text-danger">*</span></label>
                                        <select name="" id="" class="form-control" v-model="updateStatus.statusItem">
                                            <option value="waiting">Waiting</option>
                                            <option value="on_going">On Going</option>
                                            <option value="success">Success</option>
                                            <option value="failed">Failed</option>
                                        </select>

                                        <div class="row mt-4">
                                            <div class="col-6 text-left">
                                                <button style="
                                                background-color: #C4C4C4;
                                                color: white;
                                                width: 100px;
                                                height: 40px;
                                                border: none;
                                                border-radius: 3px;
                                                ">No</button>
                                            </div>
                                            <div class="col-6 text-right">
                                                <button style="
                                                background-color: #51C1AD;
                                                color: white;
                                                width: 100px;
                                                height: 40px;
                                                border: none;
                                                border-radius: 3px;
                                                " @click="updateSatusItem">Yes</button>
                                            </div>
                                        </div>

                                    </div>
                                </b-modal>
                                <!-- end modal update -->

                                <!-- End Modal -->

                                <div class="row">
                                    <div class="col-5 text-left">
                                        <button class="btn" style="
                                        background-color: #E0E0E0;
                                        color: #817F7F;
                                        position: absolute;
                                        " @click.prevent="onKembali">Cancel</button>
                                    </div>
                                    <div class="col-7 text-right">
                                        <div class="row">
                                            <div class="col-2"><b>Sub Total : </b></div>
                                            <div class="col-4"><b>Measurment : </b><span
                                                    class="text-success">{{totalMeasurment}}
                                                    cm</span></div>
                                            <div class="col-3"><b>Weight : </b><span
                                                    class="text-success">{{totalWeight}} kg</span>
                                            </div>
                                            <div class="col-3"><b>Qty : </b><span
                                                    class="text-success">{{totalQty}}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card new-card" style="
                            margin-top: 30px;
                            box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 10%) !important;
                            border-radius: 5px;
                        ">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-2 pr-1">
                                        <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                                            <label class="bold">Sales :</label>
                                            <label class="">IDR:000,000</label>
                                        </div>
                                    </div>
                                    <div class="col-2 pr-1">
                                        <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                                            <label class="bold">Cost :</label>
                                            <label class="">IDR:000,000</label>
                                        </div>
                                    </div>
                                    <div class="col-2 pr-1">
                                        <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                                            <label class="bold">Margin :</label>
                                            <label class="">IDR:000,000</label>
                                        </div>
                                    </div>
                                    <div class="col-2 pr-1">
                                        <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                                            <label class="bold">Refund :</label>
                                            <label class="">IDR:000,000</label>
                                        </div>
                                    </div>
                                    <div class="col-2 pr-1">
                                        <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                                            <label class="bold">Balance Sheet :</label>
                                            <label class="">IDR:000,000</label>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                                            <label class="bold">Deposit :</label>
                                            <label class="">IDR:000,000</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import Layout from '@layouts/main'
import axios from 'axios'
import DatePicker from 'vue2-datepicker'

export default {
    components: {
        Layout,
        Vuetable,
        DatePicker
    },
    data() {
        return {
            // fields: ['No','No Delivery','Loading','Unloading','Total Item','Status','Action'],
            isLoadingTable: true,
            fields: [
                { title: 'No', name: 'no' },
                { title: 'No Delivery', name: 'no_delivery' },
                { title: 'Loading', name: 'loading' },
                { title: 'Unloading', name: 'unloading' },
                { title: 'Total item', name: 'total_item' },
                { title: 'Status', name: 'status' },
                { title: 'Action', name: 'action' },
            ],
            items: [],
            addManual: false,
            inputNumber: true,
            noTruck: '',
            totalDelivery: '',
            totalMeasurment: '',
            totalWeight: '',
            totalQty: '',
            updateStatus: {
                statusItem: '',
                idItem: ''
            }
        }
    },
    mounted() {
        this.getDataItemDistribution()
    },

    methods: {
        onSave() {
            // this.$router.push({
            //     name: 'JobOrder Detail Distribution'
            // })
        },
        onKembali() {
            window.history.back();
        },
        loadingTable() {
            this.isLoadingTable = true
        },

        loadTableSuccess() {
            this.isLoadingTable = false
        },

        onManual() {
            if (this.addManual === false) {
                this.addManual = true
            } else {
                this.addManual = false
            }
        },
        changeInputNumber() {
            if (this.inputNumber === false) {
                this.inputNumber = true
            } else {
                this.inputNumber = false
            }
        },
        getDataItemDistribution() {
            axios({
                url: this.$store.state.rootApi + '/distribution/item/' + this.$route.params.id,
                method: 'GET',
            }).then((response) => {
                this.items = response.data[0].data_item
                this.noTruck = response.data[0].no_truck
                this.totalDelivery = 'Rp ' + response.data[0].delivery_expense.toString().replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                this.totalMeasurment = response.data[0].measurment
                this.totalWeight = response.data[0].weight
                this.totalQty = response.data[0].qty
                this.isLoadingTable = false
            })
        },
        onOpenModalStatus(props) {
            this.$bvModal.show('modal-delete-update-status')
            this.updateStatus.idItem = props.rowData.id_unloading
            this.updateStatus.statusItem = props.rowData.status
        },
        updateSatusItem() {
            this.$bvModal.hide('modal-delete-update-status')
            this.isLoadingTable = true
            axios.post(this.$store.state.rootApi + '/distribution/update-item/' + this.updateStatus.idItem, this.updateStatus, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.data === "Successfuly") {
                        this.getDataItemDistribution()
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },

				onAction(props) {

						let config = {
							headers: {
								Authorization: 'Bearer ' + localStorage.getItem('access_token'),
							},
						}
						axios({
							url: this.$store.state.rootApi + '/distribution' + '/pdf/' + props.rowData.id_unloading +'/' + props.rowData.id_distribution,
							config,
							method: 'GET',
							responseType: 'blob',
						}).then((response) => {
							console.log(props.rowData.no_delivery)
							var fileURL = window.URL.createObjectURL(new Blob([response.data]))
							var fURL = document.createElement('a')

							fURL.href = fileURL
							fURL.setAttribute('download', props.rowData.no_delivery + '.pdf')
							document.body.appendChild(fURL)

							fURL.click()
						})
				},
    },
}
</script>
<style>
.content {
    margin: 10px;
}
</style>
